<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        Marchés > Tableau de bord
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Tableau de bord
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
      <div class="div_left">
        <button class="btn btn-blue"
                @click="downloadExport(exportLink.url)"
                download>
          Exporter
        </button>
      </div>
    </div>
    <div class="row mb-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <!-- /////////////////////////////////////// ZONE IMPRIMABLE /////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <!-- <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  :filename="'Situation_marches_entreprise_'+decomptePer[0].entreprise+'_'+dateJour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-2">
          <div class="col-3 ml-4">
            <img src="../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ dateJour }}
          </div>
        </div>
        <div class="row justify-content-center my-3">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Situation des marchés de l'entreprise {{ decomptePer[0].entreprise }}
                du {{ annee_debut.split("-")[2]+"-"+annee_debut.split("-")[1]+"-"+annee_debut.split("-")[0] }}
                au {{ annee_fin.split("-")[2]+"-"+annee_fin.split("-")[1]+"-"+annee_fin.split("-")[0] }}
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <table class="table table-blue text-center"> 
            <thead>
              <tr>
                <th class="tht-dark-blue text-left"
                    scope="col">Marché</th>
                <th class="tht-dark-blue text-right"
                    scope="col">Montant (FCFA)</th>
                <th class="tht-dark-blue text-right"
                    scope="col">Décomptes reçus (FCFA)</th>
                <th class="tht-dark-blue text-right"
                    scope="col">Reste à recevoir (FCFA)</th>
                <th class="tht-dark-blue text-right"
                    scope="col">Paiements effectués (FCFA)</th>
                <th class="tht-dark-blue text-right"
                    scope="col">Reste à payer sur décompte reçu (FCFA)</th>
                <th class="tht-dark-blue text-right"
                    scope="col">Restes à payer sur engagements (FCFA)</th>
              </tr>
            </thead>
            <tbody>
              <tr scope="row"
                  @click="launchUpdate(program.id)"
                  v-for="(program,programKey) in decomptePer"
                  :key="programKey">
                <td class="text-left">{{program.numero_marche}}</td>
                <td class="text-left">{{Math.round(Number(program.montant_definifif_marche))}}</td>
                <td class="text-right">{{Math.round(Number(program.montant_total_decompte)).toLocaleString()}}</td>
                <td class="text-right">
                  {{Math.round(Number(program.montant_definifif_marche)-Number(program.montant_total_decompte)).toLocaleString() }}
                </td>
                <td class="text-right">{{Math.round(Number(program.montant_total_decompte_paye)).toLocaleString()}}</td>
                <td class="text-right">
                  {{Math.round(Number(program.montant_total_decompte)-Number(program.montant_total_decompte_paye)).toLocaleString()}}
                </td>
                <td class="text-right">
                  {{Math.round(Number(program.montant_definifif_marche-program.montant_total_decompte)
                    +Number(program.montant_total_decompte-program.montant_total_decompte_paye)).toLocaleString() }}
                </td>

              </tr>
            </tbody>
            <tfoot>
              <! -- Intergrer le total une fois disponible car on aura plus de pagination-- >
              <tr scope="row"
                  class="tht-blue">
                <td class="text-left text-uppercase">Total</td>
                <td class="text-right">{{Math.round(Number(totaux.montant)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(totaux.recu)).toLocaleString()}}</td>
                <td class="text-right">
                  {{Math.round(Number(totaux.en_attente_payement_anterieure)).toLocaleString()}}
                </td>
                
                <td class="text-right">{{Math.round(Number(totaux.paye)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(totaux.en_attente_payement)).toLocaleString()}}</td>
                <td class="text-right">
                  {{Math.round(Number(totaux.en_attente_payement_anterieure)
                    +Number(totaux.en_attente_payement)).toLocaleString()}}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        
      </div>
    </vue-html2pdf> -->
    <!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////-->
    <div class="mx-auto mt-2">
      <table class="table table-blue text-center"> 
        <thead>
          <tr>
            <th class="tht-dark-blue text-left"
                scope="col">Marché</th>
            <th class="tht-dark-blue text-right"
                scope="col">Montant (FCFA)</th>
            <th class="tht-dark-blue text-right"
                scope="col">Décomptes reçus (FCFA)</th>
            <th class="tht-dark-blue text-right"
                scope="col">Reste à recevoir (FCFA)</th>
            <th class="tht-dark-blue text-right"
                scope="col">Paiements effectués (FCFA)</th>
            <th class="tht-dark-blue text-right"
                scope="col">Reste à payer sur décompte reçu (FCFA)</th>
            <th class="tht-dark-blue text-right"
                scope="col">Restes à payer sur engagements (FCFA)</th>
          </tr>
        </thead>
        <tbody>
          <tr scope="row"
              @click="launchUpdate(program.id)"
              v-for="(program,programKey) in decomptePer"
              :key="programKey">
            <td class="text-left">{{program.numero_marche}}</td>
            <td class="text-left">{{Math.round(Number(program.montant_definifif_marche))}}</td>
            <td class="text-right">{{Math.round(Number(program.montant_total_decompte)).toLocaleString()}}</td>
            <td class="text-right">
              {{Math.round(Number(program.montant_definifif_marche)-Number(program.montant_total_decompte)).toLocaleString() }}
            </td>
            <td class="text-right">{{Math.round(Number(program.montant_total_decompte_paye)).toLocaleString()}}</td>
            <td class="text-right">
              {{Math.round(Number(program.montant_total_decompte)-Number(program.montant_total_decompte_paye)).toLocaleString()}}
            </td>
            <td class="text-right">
              {{Math.round(Number(program.montant_definifif_marche-program.montant_total_decompte)
                +Number(program.montant_total_decompte-program.montant_total_decompte_paye)).toLocaleString() }}
            </td>

          </tr>
        </tbody>
        <tfoot>
          <!-- Intergrer le total une fois disponible car on aura plus de pagination-->
          <tr scope="row"
              class="tht-blue">
            <td class="text-left text-uppercase">Total</td>
            <td class="text-right">{{Math.round(Number(totaux.montant)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(totaux.recu)).toLocaleString()}}</td>
            <td class="text-right">
              {{Math.round(Number(totaux.en_attente_payement_anterieure)).toLocaleString()}}
            </td>
            
            <td class="text-right">{{Math.round(Number(totaux.paye)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(totaux.en_attente_payement)).toLocaleString()}}</td>
            <td class="text-right">
              {{Math.round(Number(totaux.en_attente_payement_anterieure)
                +Number(totaux.en_attente_payement)).toLocaleString()}}
            </td>
          </tr>
        </tfoot>
      </table>

    </div>
  </div>
</template>
<style>
  /* .table tbody td,
  .table tfoot td,
  .table thead th,
  .form-inline input.form-control,
  .form-inline button.btn-info{
    font-size: 0.54em;
  } */
  .table tbody tr{
    cursor: pointer;
  }
</style>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex"
// import VueHtml2pdf from 'vue-html2pdf'

export default({
  name:"MarcheEntrepriseBoard",
  components: {
    // VueHtml2pdf,
  },
  data:() =>({
    decomptePer:[],
    index:1,
    dateJour:"",
    loaded:false,
    annee_debut:new Date().getFullYear()+"-01-01",
    annee_fin:new Date().getFullYear()+"-12-31",
    exportation:{
      titre:"Situation des marchés de l'entreprise",
      contenu:[],
      entete:[
        "Marché",
        "Montant",
        "Décomptes reçus",
        "Reste à recevoir",
        "Paiements effectués",
        "Reste à payer sur décomptes reçus",
        "Reste à payer sur engagements"
      ],
      nom_utilisateur:"",
      departement:"",
      date_impression:"",
      totaux:{
        numero_marche:"Totaux",
        montant:0,
        decompte_recu:0,
        reste_recevoir:0,
        decompte_paye:0,
        // engagement:0,
        decompte_en_attente_payement:0,
        // decompte_en_attente_payement_anterieure:0,
        reste_payer_engagement:0,
      }
    },
    totaux:{
      montant:0,
      recu:0,
      paye:0,
      en_attente_payement:0,
      en_attente_payement_anterieure:0
    }
  }),
  watch:{
    reportingDT(){
      if(this.reportingDT){
        this.decomptePer = this.reportingDT.donnees
        this.exportation.contenu=[]
        this.totaux.montant=0
        this.totaux.recu=0
        this.totaux.paye=0
        this.totaux.en_attente_payement=0
        this.reportingDT.donnees.forEach(report => {          
          this.totaux.recu += Number(report.montant_total_decompte)
          this.totaux.montant += Number(report.montant_definifif_marche)
          this.totaux.paye += Number(report.montant_total_decompte_paye)
          this.totaux.en_attente_payement += Number(report.montant_total_decompte) - Number(report.montant_total_decompte_paye)
          this.totaux.en_attente_payement_anterieure += Number(report.montant_definifif_marche) - Number(report.montant_total_decompte)
          this.exportation.titre="Situation des marchés de l'entreprise"+ this.decomptePer[0].entreprise+
            "du" + this.annee_debut.split("-")[2]+"-"+this.annee_debut.split("-")[1]+"-"+this.annee_debut.split("-")[0] +
            "au" + this.annee_fin.split("-")[2]+"-"+this.annee_fin.split("-")[1]+"-"+this.annee_fin.split("-")[0]
          this.exportation.contenu.push({
            rubrique : report.rubrique,
            programme : report.programme,
            libelle_entreprise : report.libelle_entreprise,
            decompte_recu : report.montant_total_decompte,
            montant : report.montant_definifif_marche,
            decompte_paye : report.montant_total_decompte_paye,
            // engagement : report.engagement,
            decompte_en_attente_payement: Number(report.montant_total_decompte) - Number(report.montant_total_decompte_paye),
            // decompte_en_attente_payement_anterieure : report.decompte_en_attente_payement_anterieure,
            reste_recevoir : Number(report.montant_definifif_marche) - Number(report.montant_total_decompte),
            reste_payer_engagement : Number(report.montant_definifif_marche-report.montant_total_decompte)
              +Number(report.montant_total_decompte-report.montant_total_decompte_paye),
          })
        })
        this.exportation.totaux.montant=this.totaux.montant
        this.exportation.totaux.decompte_paye=this.totaux.paye
        this.exportation.totaux.decompte_recu=this.totaux.recu
        // this.exportation.totaux.engagement=this.totaux.engagement
        this.exportation.totaux.decompte_en_attente_payement=this.totaux.en_attente_payement
        // this.exportation.totaux.decompte_en_attente_payement_anterieure=this.totaux.en_attente_payement_anterieure
        this.exportation.totaux.reste_recevoir= this.totaux.en_attente_payement_anterieure
        this.exportation.totaux.reste_payer_engagement=Number(this.totaux.en_attente_payement_anterieure+this.totaux.en_attente_payement)
        console.log(this.exportation)
        this.exporterDonnees(this.exportation)

        this.loaded=true
      }
    }
  },
  created() {
    this.loaded=false
    this.getReportingDT({
      annee_debut:this.annee_debut,
      annee_fin:this.annee_fin,
      liste:"liste_marche_entreprise",
      id:this.$route.params.id
    })

    var jour = new Date()
    this.dateJour=""
    if (jour.getDate() < 10) {
      this.dateJour ="0" 
    }
    this.dateJour+=jour.getDate()+"-"
    if (jour.getMonth() < 9) {
      this.dateJour +="0"
    }
    this.dateJour+=(jour.getMonth()+1)+"-"
    this.dateJour += jour.getFullYear()
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    // remplir la case date_impression pour l'exportation
    this.exportation.date_impression = this.dateJour    
    // remplir la case nom_utilisateur pour l'exportation
    this.exportation.nom_utilisateur = userdetails.nom
    
    // remplir la case departement pour l'exportation
    switch (this.user[0]) {
      case "DT":
        this.exportation.departement = "Direction Technique"
        break
      case "DCG":
        this.exportation.departement = "Direction du Contrôle de Gestion"
        break
      case "DAF":
        this.exportation.departement = "Direction Administrative et Financière/ Ingenierie Financière"
        break
      case "ADMIN":
        this.exportation.departement = "Administrateur"
        break
      default:        
        this.exportation.departement = "Autre"
        break
    }
  },
  computed:{
    ...mapGetters(["reportingDT","exportLink","failExportLink"])
  },
  methods:{
    ...mapActions(["getReportingDT","exporterDonnees"]),
    ...mapMutations(["setReportingDT","setExportLink","setFailExportLink"]),
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    changeYear(){
      this.loaded = false
      this.getReportingDT({
        annee_debut:this.annee_debut,
        annee_fin:this.annee_fin,
        liste:"liste_marche_entreprise",
        id:this.$route.params.id
      })
    },
    launchUpdate(id){
      this.$router.push({ name: "DecompteBoard", params: { id: id } })
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
})
</script>
